import React, { useContext, useEffect, useState } from "react";
import { API } from "aws-amplify";
import { useNavigate } from "react-router";
import Context from "../../Context/Context";
import WarningModal from "./WarningModal";

export default function BworkzInstructorMonthly() {
  const navigate = useNavigate();
  const { userData } = useContext(Context);
  const [showModal, setShowModal] = useState(false);
  const [paypalButtonsRendered, setPaypalButtonsRendered] = useState(false);

  const initializePayPalButtons = () => {
    const container = document.getElementById(
      "paypal-button-container-P-13Y35707T6147191KM424HOI"
    );
    if (container) {
      container.innerHTML = "";
    }

    window.paypal
      .Buttons({
        style: {
          shape: "rect",
          color: "gold",
          layout: "vertical",
          label: "subscribe",
        },

        onClick: function (data, actions) {
          if (userData.subscriptionId) {
            setShowModal(true);
            return actions.reject();
          }
          return actions.resolve();
        },

        createSubscription: function (data, actions) {
          return actions.subscription.create({
            plan_id: "P-13Y35707T6147191KM424HOI",
            application_context: {
              shipping_preference: "NO_SHIPPING",
            },
          });
        },

        onApprove: async function (data, details) {
          try {
            console.log("Subscription approved:", data);

            const addMonth = 30 * 24 * 60 * 60 * 1000;
            const postData = {
              product: "BWORKZ Instructor Monthly Paypal",
              joiningDate: Date.now(),
              renewDate: Date.now() + addMonth,
              paymentMethodEmailId: data.payerID || "---",
              subscriptionId: data.subscriptionID,
            };

            await API.put("user", "/user/payment-update/Bworkz", {
              body: postData,
            });

            const addPaymentData = {
              subscriptionId: data.subscriptionID,
              cognitoId: userData.cognitoId,
              institution: userData.institution,
              phoneNumber: userData.phoneNumber,
              emailId: userData.email,
              product: "BworkZ Instructor Monthly",
              amount: 2000,
              paymentMethod: "PayPal",
              status: "Success",
              period: "monthly",
            };

            await API.put("user", "/user/add-payment-data", {
              body: addPaymentData,
            });

            navigate("/paymentsuccessful");
          } catch (error) {
            console.error("Payment processing error:", error);
            navigate("/paymentfailed");
          }
        },

        onError: function (err) {
          console.error("PayPal error:", err);
          navigate("/paymentfailed");
        },
      })
      .render("#paypal-button-container-P-13Y35707T6147191KM424HOI");

    setPaypalButtonsRendered(true);
  };

  useEffect(() => {
    if (
      userData.product !== "BworkZ Instructor Monthly" &&
      !paypalButtonsRendered
    ) {
      initializePayPalButtons();
    }
    // eslint-disable-next-line
  }, [navigate, userData, paypalButtonsRendered]);

  return (
    <div className="w-full max-w-md mx-auto">
      <div
        id="paypal-button-container-P-13Y35707T6147191KM424HOI"
        className="mb-4"
      ></div>

      <WarningModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
}
