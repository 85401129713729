import React, { useContext, useState } from "react";
import { API } from "aws-amplify";
import { useNavigate } from "react-router";
import Context from "../../Context/Context";
import { toast } from "react-toastify";

export const CancelCard = ({ plan }) => {
  const navigate = useNavigate();
  const { userData } = useContext(Context);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleCancelSubscription = async () => {
    setIsProcessing(true);

    try {
      const { institution, cognitoId, subscriptionId } = userData;

      if (!institution || !cognitoId || !subscriptionId) {
        console.error("Missing required fields for cancellation.");
        alert("Required fields are missing. Please try again.");
        return;
      }
      const confirmCancel = window.confirm(
        "Are you sure you want to cancel your subscription? This action cannot be undone."
      );

      if (!confirmCancel) {
        setIsProcessing(false);
        return;
      }
      const response = await API.patch("user", "/user/cancel-payment", {
        body: { institution, cognitoId, subscriptionId },
      });
      if (response) {
        toast.success("Subscription cancelled successfully", {
          position: "top-right",
        });
        navigate("/");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (e) {
      console.error("Cancellation failed:", e);
      navigate("/cancellationfailed");
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="relative bg-white w-[24rem] h-[32rem] p-10 rounded-[2rem] flex flex-col items-center gap-8 border-[#FDCF08] border-[0.1rem]">
      <div className="w-full absolute top-0 z-10">
        <h2
          className="text-2xl text-white tracking-wide font-semibold py-2 text-center border-b-2 border-black rounded-t-3xl"
          style={{
            backgroundColor: "#4c4c4c",
            borderTopRightRadius: "2rem",
            borderTopLeftRadius: "2rem",
          }}
        >
          Current Subscription
        </h2>
      </div>
      <div className="relative h-full space-y-6 w-full text-center mt-8">
        <div>
          <p className="font-semibold text-xl">{plan?.productName}</p>
          <p className="text-gray-600">{plan?.description}</p>
        </div>
        <div className="flex justify-between items-center pt-6 gap-8">
          <p className="text-[1.5rem] font-bold">
            {plan?.price} / {plan?.period}
          </p>
          <div
            className="px-3 py-1 -mt-2 text-sm rounded-full text-green-800"
            style={{ backgroundColor: "#d8ffd8" }}
          >
            Active
          </div>
        </div>
        <div className="w-full mt-8">
          <button
            onClick={handleCancelSubscription}
            disabled={isProcessing}
            className={`w-[90%] text-gray-700 text-[1.3rem] font-semibold tracking-wide px-6 py-2 absolute bottom-0 right-0 mr-3 rounded-xl transition-all duration-200 ${
              isProcessing
                ? "bg-gray-300 cursor-not-allowed"
                : "hover:bg-red-700 hover:text-white"
            }`}
            style={{ backgroundColor: isProcessing ? undefined : "#ffd040" }}
          >
            {isProcessing ? (
              <div className="flex items-center justify-center gap-2">
                <svg
                  className="animate-spin h-5 w-5 text-gray-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Processing...
              </div>
            ) : (
              "Cancel Subscription"
            )}
          </button>
          <p className="mt-2 text-sm text-gray-500">
            Your subscription will be cancelled at the end of the billing period
          </p>
        </div>
      </div>
    </div>
  );
};

export default CancelCard;
