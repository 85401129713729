import React, { useContext } from "react";
import { useNavigate } from "react-router";
import CancelCard from "../Components/Subscription/CancelCard";
import CancellationCardRazorpay from "../Components/Subscription/CancellationCardRazorpay";
import RazorpayPayment from "../Components/Subscription/RazorpayPayment";
import BworkzInstructorMonthly from "../Components/Subscription/BworkzInstructorMonthly";
import BworkzInstructorYearly from "../Components/Subscription/BworkzInstructorYearly";
import Context from "../Context/Context";
import '../Components/comp/Subscription.css'

const SubscriptionCard = ({
  productName,
  description,
  price,
  period,
  Component,
  handleSignup,
}) => (
  <div className="bg-white w-[24rem] h-[32rem] p-10 rounded-[2rem] flex flex-col items-center gap-8 shadowSubscribe border-[#FDCF08] border-[0.1rem]">
    <p className="text-xl font-semibold">{productName}</p>
    <p className="text-gray-600 text-center">{description}</p>
    <h1 className="text-center w-full text-2xl font-bold">
      {price} / {period}
    </h1>
    {Component ? (
      <Component />
    ) : (
      <button
        onClick={handleSignup}
        className="w-[15rem] bg-[#ffd040] text-white px-12 py-2 rounded-2xl hover:text-[#FDCF08] hover:border-[#FDCF08] hover:border-[0.3rem] h-[3rem] flex justify-center items-center"
      >
        Subscribe
      </button>
    )}
  </div>
);

export default function Subscription() {
  const { isAuth, productList, userData } = useContext(Context);
  const navigate = useNavigate();

  const getCurrentPlan = () => {
    if (!userData.product) return null;

    const plans = {
      "BWORKZ Instructor Monthly Paypal": {
        productName: "BWORKZ Instructor Monthly",
        description: "Monthly Subscription Through PayPal",
        price: "$20.00",
        period: "Month"
      },
      "BWORKZ Instructor Yearly Paypal": {
        productName: "BWORKZ Instructor Yearly",
        description: "Yearly Subscription Through PayPal",
        price: "$200.00",
        period: "Year"
      }
    };

    return plans[userData.product];
  };

  const currentPlan = getCurrentPlan();
  const isSubscribedMonthly = (userData.product === "BWORKZ Instructor Monthly Paypal") && userData.subscriptionId;
  const isSubscribedYearly = (userData.product === "BWORKZ Instructor Yearly Paypal") && userData.subscriptionId;

  // Check if user has Razorpay subscription for a specific product
  const isRazorpaySubscribed = (productId) => {
    return userData.subscriptionId && 
           userData.productIds && 
           userData.productIds.includes(productId) &&
           userData.status === "Active";
  };

  return (
    <section className="Back text-[1.5rem] flex flex-col items-center h-[90rem] max980:h-auto justify-center gap-[5rem] pb-20">
      <div className="text-center mt-20">
        <h1>INSTRUCTOR TRAINING FEES</h1>
        <h3 className="text-[1rem]">See what are the pricing in details</h3>
      </div>

      <div className="flex flex-wrap justify-center w-[90vw] max-w-[80rem] gap-[8rem]">
        {isSubscribedMonthly ? (
          <>
            <CancelCard plan={currentPlan} />
            <SubscriptionCard
              productName="BWORKZ Instructor Yearly"
              description="Yearly Subscription Through PayPal"
              price="$200.00"
              period="Year"
              Component={isAuth ? BworkzInstructorYearly : null}
              handleSignup={() => navigate("/auth")}
            />
          </>
        ) : isSubscribedYearly ? (
          <>
            <SubscriptionCard
              productName="BWORKZ Instructor Monthly"
              description="Monthly Subscription Through PayPal"
              price="$20.00"
              period="Month"
              Component={isAuth ? BworkzInstructorMonthly : null}
              handleSignup={() => navigate("/auth")}
            />
            <CancelCard plan={currentPlan} />
          </>
        ) : (
          <>
            <SubscriptionCard
              productName="BWORKZ Instructor Monthly"
              description="Monthly Subscription Through PayPal"
              price="$20.00"
              period="Month"
              Component={isAuth ? BworkzInstructorMonthly : null}
              handleSignup={() => navigate("/auth")}
            />
            <SubscriptionCard
              productName="BWORKZ Instructor Yearly"
              description="Yearly Subscription Through PayPal"
              price="$200.00"
              period="Year"
              Component={isAuth ? BworkzInstructorYearly : null}
              handleSignup={() => navigate("/auth")}
            />
          </>
        )}
      </div>

      <div className="flex flex-wrap justify-center w-[90vw] max-w-[80rem] gap-28">
        {productList.map((item) => {
          const isSubscribed = isRazorpaySubscribed(item.productId);
          
          if (isSubscribed) {
            return (
              <CancellationCardRazorpay
                key={item.productId}
                plan={{
                  productName: item.heading,
                  description: item.provides.join(" • "),
                  price: parseInt(item.amount) / 100,
                  period: item.durationText,
                  currency: item.currency,
                  productId:item.productId
                }}
              />
            );
          }

          return (
            <li
              key={item.productId}
              className="w-[24rem] h-auto p-10 rounded-[2rem] flex flex-col items-center gap-8 shadowSubscribe border-[#FDCF08] bg-white"
            >
              <p className="text-xl font-semibold">{item.heading}</p>
              <ul className="h-auto pl-0 flex flex-col">
                {item.provides.map((feature, index) => (
                  <li key={index}>
                    <p className="text-gray-600 text-center">{feature}</p>
                  </li>
                ))}
              </ul>
              <h1 className="text-center w-full text-2xl font-bold">
                {(item.currency === "INR" ? "₹ " : "$ ") +
                  parseInt(item.amount) / 100 +
                  "/" +
                  item.durationText}
              </h1>
              {isAuth ? (
                <RazorpayPayment productId={item.productId} />
              ) : (
                <button
                  onClick={() => navigate("/auth")}
                  className="w-[15rem] text-white bg-[#FDCF08] px-12 py-2 rounded-2xl hover:text-lightPrimaryColor hover:border-lightPrimaryColor"
                >
                  Sign Up
                </button>
              )}
            </li>
          );
        })}
      </div>
    </section>
  );
}