import React, { useContext, useEffect, useState } from "react";
import { API } from "aws-amplify";
import { useNavigate } from "react-router";
import Context from "../../Context/Context";
import WarningModal from "./WarningModal";


export default function BworkzInstructorMonthly() {
  const navigate = useNavigate();
  const { userData } = useContext(Context);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (userData.product !== "BworkZ Instructor Monthly") {
      const container = document.getElementById(
        "paypal-button-container-P-2RP11673933228435M45NKAI"
      );
      if (container) {
        container.innerHTML = "";
      }

      window.paypal
        .Buttons({
          style: {
            shape: "rect",
            color: "gold",
            layout: "vertical",
            label: "subscribe",
          },
          onClick: function (data, actions) {
            if (userData.subscriptionId) {
              setShowModal(true);
              return actions.reject();
            }
            return actions.resolve();
          },

          createSubscription: function (data, actions) {
            return actions.subscription.create({
              plan_id: "P-2RP11673933228435M45NKAI",
              application_context: {
                shipping_preference: "NO_SHIPPING",
              },
            });
          },

          onApprove: async function (data, details) {
            try {
              console.log("Subscription approved:", data);

              const addYear = 365 * 24 * 60 * 60 * 1000;
              const postData = {
                product: "BWORKZ Instructor Yearly Paypal",
                joiningDate: Date.now(),
                renewDate: Date.now() + addYear,
                paymentMethodEmailId: data.payerID || "---",
                subscriptionId: data.subscriptionID,
              };

              // Update payment data (existing API call)
              await API.put("user", "/user/payment-update/Bworkz", {
                body: postData,
              });

              // New API call to add payment data
              const addPaymentData = {
                subscriptionId: data.subscriptionID,
                cognitoId: userData.cognitoId,
                institution: userData.institution,
                phoneNumber: userData.phoneNumber,
                emailId: userData.email,
                product: "BworkZ Instructor Monthly",
                amount: 2000, // Replace with actual amount
                paymentMethod: "PayPal", // Replace with actual payment method
                status: "Success", // Payment status
                period: "monthly",
              };

              await API.put("user", "/user/add-payment-data", {
                body: addPaymentData,
              });

              navigate("/paymentsuccessful");
            } catch (error) {
              console.error("Payment processing error:", error);
              navigate("/paymentfailed");
            }
          },

          onError: function (err) {
            console.error("PayPal error:", err);
            navigate("/paymentfailed");
          },
        })
        .render("#paypal-button-container-P-2RP11673933228435M45NKAI");
    }
  }, [navigate, userData]);

  return (
    <div className="w-full max-w-md mx-auto">
        <div
          id="paypal-button-container-P-2RP11673933228435M45NKAI"
          className="mb-4"
      ></div>
            <WarningModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
}
