import React, { useContext, useState, useEffect } from "react";
import { API } from "aws-amplify";
import Context from "../../Context/Context";
import RazorpayPayment from '../Subscription/RazorpayPayment';
import PayPalSubscription from '../Subscription/PayPalSubscriptionONHOLD';
import NavBar from "../NavBar";

export default function Cart() {
  const Ctx = useContext(Context);
  const { getCartItems, cartState, setCartState } = useContext(Context);
  const { productItems } = cartState;
  const UserCtx = Ctx.userData;

  const [removingItems, setRemovingItems] = useState({});

  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const cartData = await getCartItems(UserCtx.cognitoId);
        if (cartData) {
          setCartState(cartData);
        }
      } catch (error) {
        console.error('Error fetching cart items:', error);
        setCartState({ productItems: [], quantities: [], subtotal: 0, currencySymbol: '$' });
      }
    };

    fetchCartItems();
    //eslint-disable-next-line
  }, []);

  const deleteCartItem = async (itemId) => {
    setRemovingItems((prev) => ({ ...prev, [itemId]: true }));

    try {
      await API.del(
        "awsaiapp",
        `/any/deleteCartItem/${UserCtx.institution}/${UserCtx.cognitoId}`,
        { body: { productId: itemId } }
      );

      // Update the local state immediately
      setCartState((prevState) => ({
        ...prevState,
        productItems: prevState.productItems.filter(item => item.productId !== itemId),
        subtotal: prevState.subtotal - (prevState.productItems.find(item => item.productId === itemId)?.amount || 0) / 100
      }));

      console.log(`Item ${itemId} deleted successfully`);

      // Optionally, fetch the updated cart data
      const updatedCart = await getCartItems(UserCtx.cognitoId);
      if (updatedCart) {
        console.log("Updated cart:", updatedCart);
        setCartState(updatedCart);
      }
    } catch (error) {
      console.error("Error deleting cart item:", error);
    } finally {
      setRemovingItems((prev) => {
        const newState = { ...prev };
        delete newState[itemId];
        return newState;
      });
    }
  };

  const getSubscriptionDuration = (subscriptionType) => {
    switch (subscriptionType) {
      case 'Monthly':
        return 30;  // 30 days
      case 'Yearly':
        return 365; // 365 days
      case 'Weekly':
        return 7;   // 7 days
      default:
        return 0;   // Fallback to 0 if unknown type
    }
  };

  const renderPaymentButton = () => {
    const product = productItems[0];
    const duration = getSubscriptionDuration(product.subscriptionType); // Get duration based on subscription type
    const currency = product.currency;

    if (currency === 'INR') {
      return <div><RazorpayPayment productId={product.productId} /></div>;
    } else {
      return <PayPalSubscription planId={product.planId} duration={duration} />; // Pass duration as prop
    }
  };

  return (
    <div className="h-screen">
      <NavBar />
      <div className="max-w-4xl mx-auto p-4 poppins h-[95%] flex flex-col justify-evenly">
        {productItems.length === 0 ? (
          <p className="animate-fade-in mx-auto text-xl font-bold">Your cart is empty.</p>
        ) : (
          <>
            <table className="w-full mb-8 animate-slide-in">
              <thead className="text-[#424242] border-b text-[1.1rem] tracking-[1px]">
                <tr>
                  <th className="py-2 px-4 text-left font-[600]">Item</th>
                  <th className="py-2 px-4 text-left font-[600] max500:hidden">Description</th>
                  <th className="py-2 px-4 text-right font-[600]">Price</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {productItems.map((item) => (
                  <tr
                    key={item.productId}
                    className={`border-b transition-all duration-500 ease-out ${removingItems[item.productId] ? 'animate-slide-out' : ''
                      }`}
                  >
                    <td className="py-4 px-4 text-md max600:px2 max600:text-sm">{item.heading}</td>
                    <td className="py-4 px-4 text-md max600:px2 max600:text-sm max500:hidden">{item.description}</td>
                    <td className="py-4 px-4 text-md max600:px2 max600:text-sm text-right">
                      {item.currency === 'INR' ? '₹' : '$'}{(item.amount / 100).toFixed(2)}
                    </td>
                    <td>
                      <button
                        onClick={() => deleteCartItem(item.productId)}
                        className="hover:scale-110 transition-transform duration-300"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="black" className="w-5">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="mb-8 max-w-md">
              <h2 className="text-xl font-bold mb-4 animate-fade-in">Cart Total</h2>
              <div className="flex justify-between mb-2">
                <span>Subtotal</span>
                <p>{cartState.currencySymbol}{cartState.subtotal.toFixed(2)}</p>
              </div>
              <div className="flex justify-between mb-2">
                <span>Discount</span>
                <span>0%</span>
              </div>
              <div className="flex justify-between font-bold">
                <span>Total</span>
                <p>{cartState.currencySymbol}{(cartState.subtotal).toFixed(2)}</p>
              </div>
              <div className="mb-4 w-full max-w-md">
                <div className="hover:scale-105 transition-transform duration-300">
                  {renderPaymentButton()}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
