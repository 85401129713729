import React, { useContext, useState } from "react";
import { API } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import Context from "../../Context/Context";
import { toast } from "react-toastify";
import RazorpayIcon from "../../Utils/Assests/razorpay.svg";
import WarningModal from "./WarningModal";

const RazorpayPayment = ({ productId }) => {
  const { userData } = useContext(Context);
  const Navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const RAZORPAY_KEY_ID =
    process.env.REACT_APP_STAGE === "PROD"
      ? process.env.REACT_APP_RAZORPAY_LIVE_KEY_ID
      : process.env.REACT_APP_RAZORPAY_TEST_KEY_ID;

  const handleSubscribe = async () => {
    if (userData.subscriptionId) {
      setShowModal(true);
      return;
    }

    try {
      const response = await API.put("awsaiapp", `/payment/checkout`, {
        body: {
          institutionId: userData.institution,
          cognitoId: userData.cognitoId,
          productId: [productId],
          discountCode: "",
        },
      });

      const subscriptionResult = response[0].subscriptionResult;
      const invoiceId = response[0].invoiceId;

      const options = {
        key: RAZORPAY_KEY_ID,
        amount: subscriptionResult.amount * 100,
        currency: subscriptionResult.currency,
        name: userData.institution.toUpperCase(),
        subscription_id: subscriptionResult.paymentId,
        description: subscriptionResult.subscriptionType,
        handler: async function (paymentResponse) {
          try {
            const verifyResponse = await API.put(
              "awsaiapp",
              `/payment/webhook`,
              {
                body: {
                  institutionId: userData.institution,
                  cognitoId: userData.cognitoId,
                  subscriptionIds: [subscriptionResult.paymentId],
                  products: [],
                  razorpay_payment_id: paymentResponse.razorpay_payment_id,
                  amount: subscriptionResult.amount,
                  referralCode: "",
                  invoiceId,
                },
              }
            );

            if (verifyResponse.signatureIsValid) {
              toast.success("Payment successful!");
              Navigate("/");
              setTimeout(() => {
                window.location.reload();
              }, 100);
            } else {
              throw new Error("Payment verification failed!");
            }
          } catch (error) {
            console.error("Payment verification error:", error);
            toast.error("Payment verification failed.");
          }
        },
        prefill: {
          email: subscriptionResult.emailId,
        },
        notes: {
          cognitoId: userData.cognitoId,
          productId: productId,
          planId: "",
        },
        theme: {
          color: "#205b8f",
        },
        modal: {
          ondismiss: async function () {
            try {
              await API.del("awsaiapp", `/cancel/payment`, {
                body: {
                  cognitoId: userData.cognitoId,
                  subscriptionIds: [subscriptionResult.paymentId],
                },
              });
            } catch (error) {
              console.log(error);
            }
          },
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      toast.error("Error during checkout.", {
        position: "top-right",
      });
      console.error("Error in checkout:", error);
    }
  };

  return (
    <div className="z-1">
      <button
        className={`w-[18rem] text-white px-12 py-2 rounded-2xl hover:text-[#FDCF08] hover:bg-white hover:border-lightPrimaryColor hover:border-[0.2rem] h-[3rem] flex justify-center items-center mt-16 max450:w-[60vw] cursor-pointer`}
        style={{
          backgroundColor: "#FDCF08",
        }}
        onClick={handleSubscribe}
      >
        <img src={RazorpayIcon} alt="RazorpayIcon" style={{ width: "8rem" }} />
        <p className="text-gray-800 font-extralight text-[1rem] mb-0 mt-2 ml-1">
          Subscribe
        </p>
      </button>
      <p className="text-sm flex gap-1 justify-center mt-2">
        Powered by{" "}
        <img className="w-20" src={RazorpayIcon} alt="RazorpayIcon2" />
      </p>

      <WarningModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
};

export default RazorpayPayment;